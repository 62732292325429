@font-face {
     font-family: 'Circe';
     src: url('../../../fonts/Circe-Regular.eot');
     src: url('../../../fonts/Circe-Regular.eot?#iefix') format('embedded-opentype'),
          url('../../../fonts/Circe-Regular.svg#Circe-Regular') format('svg'),
          url('../../../fonts/Circe-Regular.ttf') format('truetype'),
          url('../../../fonts/Circe-Regular.woff') format('woff'),
          url('../../../fonts/Circe-Regular.woff2') format('woff2');
     font-weight: normal;
     font-style: normal;
}
@font-face {
     font-family: 'Circe';
     src: url('../../../fonts/Circe-Bold.eot');
     src: url('../../../fonts/Circe-Bold.eot?#iefix') format('embedded-opentype'),
          url('../../../fonts/Circe-Bold.svg#Circe-Bold') format('svg'),
          url('../../../fonts/Circe-Bold.ttf') format('truetype'),
          url('../../../fonts/Circe-Bold.woff') format('woff'),
          url('../../../fonts/Circe-Bold.woff2') format('woff2');
     font-weight: bold;
     font-style: normal;
}
@font-face {
     font-family: 'Circe';
     src: url('../../../fonts/Circe-Light.eot');
     src: url('../../../fonts/Circe-Light.eot?#iefix') format('embedded-opentype'),
          url('../../../fonts/Circe-Light.svg#Circe-Light') format('svg'),
          url('../../../fonts/Circe-Light.ttf') format('truetype'),
          url('../../../fonts/Circe-Light.woff') format('woff'),
          url('../../../fonts/Circe-Light.woff2') format('woff2');
     font-weight: 300;
     font-style: normal;
}