@import '../../../assets/scss/variables.scss';

.addoffer-modal {
    .ant-modal-close {
        top: 15px;
        right: 15px;

        .ant-modal-close-x {
            width: 25px;
            height: 25px;
            line-height: 25px;

            .icon {
                fill: $white;
                width: 25px;
            }
        }
    }

    .ant-modal-content {
        background: $dark-color;
        color: $white;
        font-size: 16px;
        border-radius: 10px;
        border: 1px solid #7180924E;
    }

    h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .slick-slider {
        margin-top: 0;
        margin-bottom: 25px;

        .slick-arrow {
            position: absolute;
            width: auto;

            &::before {
                display: none;
            }

            .icon {
                fill: $white;
                width: 8px;
            }

            &.slick-prev {
                top: -22px;
                right: 20px;
                left: auto;
            }

            &.slick-next {
                top: -22px;
                right: 0;
                left: auto;
            }

            &.slick-disabled {
                cursor: inherit;

                .icon {
                    fill: #718092;
                }
            }
        }

        .slick-list {
            margin: 0 -10px;

            .slick-track {
                .slick-slide {
                    padding: 0 10px;
                    position: relative;
                    &:nth-child(2) {
                        .recent-red-card{
                            position: relative;
                            &::after {
                                content: ' ';
                                position: absolute;
                                background-color: #6FC3CE;
                                right: -1px;
                                left: auto;
                                width: 26px;
                                height: 25px;
                                bottom: -1px;
                                z-index: 2;
                                top: auto;
                                border-radius: 15px 0 10px 0;
                                font-size: 17px;
                                text-align: center;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.479' height='10.457' viewBox='0 0 14.479 10.457'%3E%3Cpath id='Icon_feather-check' data-name='Icon feather-check' d='M9.326 17.957a.8.8 0 0 1-.569-.236L4.736 13.7a.8.8 0 1 1 1.138-1.138l3.453 3.453 8.279-8.279a.8.8 0 0 1 1.138 1.138L9.895 17.721A.8.8 0 0 1 9.326 17.957Z' transform='translate(-4.5 -7.5)' fill='%23fff'/%3E%3C/svg%3E");
                                background-repeat: no-repeat;
                                background-position: center center;
                            }
                        }
                    }
                }
            }
        }
    }

    .recent-red-card {
        display: flex;
        flex-direction: column;
        transition: all ease-in 0.1s;
        min-height: 141px;
    
        &:hover {
          background: linear-gradient(0deg, #FFF1EB 0%, #FCFCFC 100%) 0% 0% no-repeat padding-box;
          border-color: #FFFFFF;
        }
    
        .card-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
    
          .right-img {
            width: 35px;
            height: 35px;
            border-radius: 12px;
    
            img {
              width: 100%;
            }
          }
        }
    
        .card-bottom {
          margin-top: auto;
    
          h4 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 3px;
          }
    
          p {
            margin-bottom: 0;
            color: $label-color;
            font-size: 11px;
          }
        }
        &:hover{
          .card-header {
            h3 {
              color: $dark-color;
            } 
          }
          .card-bottom{
            h4{
              color: $dark-color;
            }
          }
        }
      }

    .upper-header {
        margin-bottom: 10px;

        h4 {
            font-size: 16px;
            margin-bottom: 4px;
        }

        p {
            color: $label-color;
        }

        label {
            color: $label-color;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .ant-checkbox-wrapper {
            .ant-checkbox {
                top: 0;
                margin-left: 8px;
                &::after {
                    display: none;
                }

                .ant-checkbox-inner {
                    background: $dark-color;
                    border-color: #6FC3CE;
                    border-radius: 4px;
                    width: 20px;
                    height: 20px;
                }

                &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                        background: #6FC3CE;
                        border-color: #6FC3CE;
                    }
                }
            }
        }
    }

    .ant-radio-group {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        margin-bottom: 60px;

        .ant-radio-button-wrapper {
            height: 120px;
            border-radius: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            position: relative;

            @media screen and (max-width: 480px) {
                height: 90px;
            }

            @media screen and (max-width: 400px) {
                height: 80px;
            }

            label {
                position: absolute;
                bottom: -35px;
                width: 100%;
                text-align: center;
                left: 0;
                right: 0;
                color: $label-color;
                @media screen and (max-width: 480px) {
                    font-size: 12px;
                }
            }

            img {
                max-width: 100%;
                max-height: 70px;
                transition: all ease-in 0.1s;
            }

            &::before {
                content: ' ';
                position: absolute;
                background-color: #6FC3CE;
                right: 0;
                left: auto;
                width: 26px;
                height: 25px;
                bottom: 0;
                top: auto;
                border-radius: 17px 0 17px 0;
                font-size: 17px;
                text-align: center;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.479' height='10.457' viewBox='0 0 14.479 10.457'%3E%3Cpath id='Icon_feather-check' data-name='Icon feather-check' d='M9.326 17.957a.8.8 0 0 1-.569-.236L4.736 13.7a.8.8 0 1 1 1.138-1.138l3.453 3.453 8.279-8.279a.8.8 0 0 1 1.138 1.138L9.895 17.721A.8.8 0 0 1 9.326 17.957Z' transform='translate(-4.5 -7.5)' fill='%23fff'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center center;
                display: none;
            }

            &:nth-child(3n + 1) {
                background: #FFF1EB;

                &:hover {
                    background: #FFF1EB;
                }
            }

            &:nth-child(3n + 2) {
                background: #EEF6F7;

                &:hover {
                    background: #EEF6F7;
                }
            }

            &:nth-child(3n + 3) {
                background: #DAD3DA;

                &:hover {
                    background: #DAD3DA;
                }
            }

            &:hover {
                img {
                    transform: scale(1.2);
                }
            }

            &.ant-radio-button-wrapper-checked {
                &::before {
                    display: block;
                }

                &:hover {
                    &::before {
                        content: ' ';
                        background-color: #6FC3CE;
                    }
                }

                label {
                    color: $white;
                }
            }
        }
    }
}