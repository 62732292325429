@import '../../assets/scss/variables.scss';

.outletmgt-table{
    .ant-table-body{
        max-height: calc(100vh - 550px);
        overflow-y: auto !important;
    }
}
.ant-select-selector {
    position: relative;
    background-color: #132C4A!important;
    border-color: #7180924E!important;
    color: #d9d9d9!important;
    height:35px!important;
    font-size: 15px!important;
    border-radius: 4px!important;
    
}
.ant-select-selection-item {
    margin-top: 5px!important;
}

.custom-table .ant-table table .ant-table-tbody tr td .name-with-icon .table-image-icon img {
    width: 100% !important;
    height: 100% !important;
     margin-left: 0px !important;
}