@import '../../assets/scss/variables.scss';

.inner-wrapper {
  .title-header {
    p {
      font-size: 18px;
      margin-bottom: 0;
    }

    .ant-select {
      .ant-select-selector {
        font-size: 24px;
        font-weight: 600;
        background: none;
        padding: 0 20px 0 0;
        border: 0;
        color: $white;
        outline: none !important;
        box-shadow: none !important;
      }

      .ant-select-arrow {
        .icon {
          fill: $white;
          width: 13px;
        }
      }
    }
  }

  .statcis-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    @media screen and (max-width:1200px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px 25px;
    }

    @media screen and (max-width:476px) {
      display: flex;
      overflow-x: auto;
      margin-top: 15px;
    }

    .statcis-item {
      padding: 20px;
      border-radius: 10px;
      color: $dark-color;
      margin-top: 22px;
      position: relative;
      overflow: hidden;
      transition: all ease-in 0.1s;
      @media screen and (max-width:1200px) {
        margin-top: 12px;
      }
      @media screen and (max-width:476px) {
        min-width: 80vw;
      }
      &:hover{
        transform: scale(1.03);
        @media screen and (max-width:476px) {
          transform: none;
        }
      }

      .total-sale-icon{
        position: absolute;
        bottom: 20px;
        right: -30px;
        width: 110px;
        fill: rgba($white, 0.6);
      }

      .statcis-item-inner {
        display: flex;
        flex-direction: column;
        position: relative;

        .top-icon {
          border-radius: 11px;
          height: 38px;
          width: 38px;
          background: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 10px;
          right: 10px;

          .icon {
            fill: $dark-color;
            max-height: 16px;
          }
        }

        .item-upper {
          min-height: 75px;

          small {
            height: 14px;
            font-size: 13px;
          }

          h3 {
            font-size: 30px;
            font-weight: 600;
            color: $dark-color;
            margin-bottom: 0;
          }
        }

        .item-bottom {
          margin-top: auto;

          p {
            font-size: 17px;
            margin-bottom: 0;
          }

          small {
            font-size: 13px;
          }
        }
      }

      &:nth-child(1) {
        background: transparent linear-gradient(302deg, #FFCCB5 0%, #FCFCFC 100%) 0% 0% no-repeat padding-box;
      }

      &:nth-child(2) {
        background: #E3E7FF 0% 0% no-repeat padding-box;
      }

      &:nth-child(3) {
        background: #FFE0E8 0% 0% no-repeat padding-box;
      }

      &:nth-child(4) {
        background: #E8F9FF 0% 0% no-repeat padding-box;
      }
    }
  }

  .left-chart-card {
    margin-top: 40px;
  }

  .right-chart-card {
    margin-top: 40px;
  }
}

.ant-select-dropdown {
  &.header-top-select {
    border-radius: 5px;
    padding: 15px 20px;
    min-width: 279px !important;

    .ant-select-item {
      background: none;
      border-bottom: 1px solid #7180924E;
      padding: 15px 0;
      font-size: 16px;
      color: $secondary-color;

      &:first-child {
        padding-top: 5px;
      }

      &:last-child {
        border: 0;
      }
    }
  }
}

.chart-y-lebel {
  text {
    fill: #718092 !important;
  }
}

.chart-x-lebel {
  text {
    fill: #718092 !important;
    text-transform: uppercase;
  }
}

.chart-tooltip {
  background: $white !important;
  display: flex;
  min-width: 110px;
  align-items: center;
  border-radius: 4px;

  .tooltip-left {
    text-transform: uppercase;
    background: transparent linear-gradient(302deg, #FF976A 0%, #FCFCFC 100%) 0% 0% no-repeat padding-box;
    text-align: center;
    padding: 5px 10px;
    border-radius: 4px 0 0 4px !important;
    font-size: 11px;

    b {
      font-weight: bold;
      font-size: 14px;
    }
  }

  .tooltip-right {
    height: 100%;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    width: 41px;
    color: $dark-color;
  }
}

.highcharts-credits {
  display: none !important;
}

.offer-redemptionchart-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #12263E;
  border-radius: 6px;
  padding: 10px 15px;
  margin-top: 12px;

  .redemptionchart-left {
    p {
      color: $primary-color;
      margin-bottom: 0;
    }

    h4 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
    }

    label {
      color: $label-color;
    }
  }

  .redemptionchart-right {
    img {
      max-height: 46px;
    }
  }

  &.row2 {
    background: linear-gradient(76deg, #FFCCB5 0%, #FCFCFC 100%) 0% 0%;

    .redemptionchart-left {
      h4 {
        color: $dark-color;
      }
    }
  }
}

.recent-redemptions-row {
  margin-top: 30px;
  .title{
    font-size: 17px;
    font-weight: 600;
  }
  .slick-slider{
    margin-top: 20px;
    .slick-arrow{
      position: absolute;
      width: auto;
      &::before {
        display: none;
      }
      .icon {
        fill: $white;
        width: 10px;
      }
      &.slick-prev{
        top: -20px;
        right: 25px;
        left: auto;
      }
      &.slick-next{
        top: -20px;
        right: 0;
        left: auto;
      }
      &.slick-disabled{
        cursor: inherit;
        .icon {
          fill: #718092;
        }
      }
    }
    .slick-list{
      margin: 0 -15px;
      .slick-track{
        .slick-slide{
          padding: 0 15px;
        }
      }
    }
  }
  .recent-red-card {
    display: flex;
    flex-direction: column;
    transition: all ease-in 0.1s;
    min-height: 155px;

    &:hover {
      background: linear-gradient(0deg, #FFF1EB 0%, #FCFCFC 100%) 0% 0% no-repeat padding-box;
      border-color: #FFFFFF;
    }

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      p {
        font-size: 12px;
        color: $primary-color;
        margin-bottom: 0;
      }

      h3 {
        font-size: 24px;
        font-weight: 600;
      }

      .right-img {
        width: 35px;
        height: 35px;
        border-radius: 12px;

        img {
          width: 100%;
        }
      }
    }

    .card-bottom {
      margin-top: auto;

      h4 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 3px;
      }

      p {
        margin-bottom: 0;
        color: $label-color;
        font-size: 11px;
      }
    }
    &:hover{
      .card-header {
        h3 {
          color: $dark-color;
        } 
      }
      .card-bottom{
        h4{
          color: $dark-color;
        }
      }
    }
  }
}

.platforms-card{
  margin-top: 40px;
  .platform-list{
    max-height: 258px;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba($primary-color, .3);
      border-radius: 6px;
      height: 2.5px;
      width: 2.5px;
    }
    
    &::-webkit-scrollbar {
      width: 2.5px;
      height: 2.5px;
      background-color: #ffffff;
      border-radius: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
      border-radius: 6px;
      width: 2.5px;
    }

    .platform-list-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #7180924E;
      padding-bottom: 12px;
      margin-bottom: 12px;
      
      .platform-list-left{
        display: flex;
        align-items: center;
        font-size: 14px;
        .left-image{
          width: 40px;
          height: 40px;
          border-radius: 40px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          img{
            width: 100%;
          }
        }
      }
      .platform-list-right{
        text-align: right;
        small{
          color: $primary-color;
          font-size: 14px;
          line-height: 1;
        }
        h3{
          font-size: 24px;
          font-weight: 600;
          line-height: 1;
          margin-bottom: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.activeoffer-card{
  margin-top: 40px;
  .activeoffer-row {
    display: flex;
    align-items: center;
    background: #12263E;
    border-radius: 6px;
    padding: 10px 15px;
    margin-top: 14px;
    min-height: 74px;
    .activeoffer-img{
      width: 60px;
      margin-right: 6px;
      img{
        max-height: 60px;
        max-width: 100%;
      }
    }
    .redemptionchart-left {
      p {
        color: $primary-color;
        margin-bottom: 0;
        font-size: 15px;
      }
  
      h4 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
      }
  
      label {
        color: $label-color;
      }
    }
    &.add-offer{
      border: 1px dashed #718092;
      background: $dark-color;
      border-radius: 6px;
      cursor: pointer;
      transition: all ease-in 0.1s;
      .activeoffer-img{
        margin-right: 0;
        text-align: center;
        width: 55px;
      }
      .plus-icon{
        background: #6FC3CE;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in 0.1s;
        .icon {
          fill: $white;
        }
      }
      &:hover{
        border-color: rgba($primary-color, 0.5);
        .plus-icon{
          background: $primary-color;
        }
      }
    }
  }
}