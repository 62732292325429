@import '../../assets/scss/variables.scss';

.login-wrapper{
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-bg{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .banner-video{
    min-height: 100vh;
    min-width: 100%;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    @media screen and (max-width: 1300px) {
      width: auto;
    }
}
  .login-inner{
    margin: auto;
    width: 100%;
    max-width: 364px;
    position: relative;
    z-index: 2;
    .login-head{
      text-align: center;
      margin-bottom: 2.5rem;
      img{
        width: 50px;
        margin-bottom: 1.5rem;
      }
      h1{
        font-size: 70px;
        margin-bottom: 10px;
      }
      h4{
        font-size: 30px;
        font-weight: 400;
      }
    }
    .login-card{
      width: 100%;
      max-width: 342px;
      background: rgba($white, 0.36);
      padding: 30px;
      margin: auto;
      border-radius: 10px;
      .ant-form{
        margin: 0;
        .ant-input {
          &::placeholder {
            font-size: 16px;
            color: $secondary-color;
          }
        }
        .ant-input-prefix{
          .icon {
            width: 20px;
          }
        }
      }
      p{
        text-align: center;
        margin-bottom: 0;
        color: $white;
      }
    }
  }
}